.badge-circle {
	border-radius: 100% !important;
}

.badge {
	font-size: 12px;
	line-height: 1.15;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	font-weight: 600;
}

.badge-green {
	background-color: #9bd6c1;
	color: $white;
}

.badge-yellow {
	background-color: #ead185;
	color: $white;
}

.badge-pink {
	background-color: #e67a7a;
	color: $white;
}



