.client-logo-02 {
	.item {
		flex: 0 0 20%;
		max-width: 20%;
	}

	@include media-breakpoint-down(md) {
		.item {
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
	@include media-breakpoint-down(sm) {
		.item {
			flex: 0 0 33.33%;
			max-width: 33.33%;
		}
	}
	@include media-breakpoint-down(xs) {
		.item {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}

}