.heading-home-01 {
	line-height: 0.96;
}

.category-slider {
	margin-right: 0;
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: calc(100vw / 2 - 962px / 2 + 75%);
		margin-right: calc(0px - (100vw / 2 - 962px / 2));
	}
	@media (min-width: 1200px) {
		width: calc(100vw / 2 - 1170px / 2 + 75%);
		margin-right: calc(0px - (100vw / 2 - 1170px / 2));
	}

	@media screen and (min-width: 1750px) {
		width: calc(100vw / 2 - 1720px / 2 + 75%);
		margin-right: calc(0px - (100vw / 2 - 1720px / 2));

	}
}

.room-inspiration {
	.imgage img {
		width: 100%;
	}

	.content {
		padding-left: 15px;
		padding-right: 15px;
		@include media-breakpoint-up(sm) {
			padding-right: calc((100vw / 2 - 546px / 2));
			padding-left: calc((100vw / 2 - 546px / 2));
		}
		@include media-breakpoint-up(md) {
			padding-right: calc((100vw / 2 - 738px / 2));
			padding-left: calc((100vw / 2 - 738px / 2));
		}
		@include media-breakpoint-up(lg) {
			padding-right: calc((100vw / 2 - 962px / 2));
			padding-left: 30px;
		}
		@media (min-width: 1200px) {
			padding-right: calc(100vw / 2 - 1200px / 2);
			padding-left: 173px;
		}

	}
}

.image-marler-slider {
	@include media-breakpoint-down(md) {
		.image-marker {
			height: calc(100vh - 72px);
		}
		img{
			height: 100%;
		}
	}
}